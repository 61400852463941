.ui-dg-columns {
	display: flex;
	flex-direction: row;
	height: 64px;
	align-items: flex-end;
}

.ui-dg-column>button {
	margin-left: -7px;
}

.ui-dg-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.ui-dg-cell-content {
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}